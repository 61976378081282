import { Box, Flex, Image, Text } from '@chakra-ui/react';
import { React, useEffect, useState } from 'react';
import { Outlet, useNavigate, useParams } from 'react-router-dom';
import navlink from '../constant/link';
import { usePathStore } from '../hooks/useStore';
import { getServerTimeApi } from '../api/publicApi';
import { useQuery } from '@tanstack/react-query';
import dayjs from 'dayjs';

const WebNavbar = ({ date, setDate, maxDate }) => {
	const navigate = useNavigate();
	const { siteName } = useParams();
	const [storeLink, setStoreLink] = useState(sessionStorage.getItem('navLink') ?? 'pcsoResult');
	const setPcsoTab = usePathStore((state) => state.setPcsoTab);
	const getServerTime = useQuery(['getServerTimeApi'], getServerTimeApi);
	const handleClick = (item) => {
		setStoreLink(item.name);
		sessionStorage.setItem('navLink', item.name);
		if (siteName !== item.link) {
			navigate(`/${item.link}`);
		}
	};

	useEffect(() => {
		if (storeLink !== siteName) {
			setStoreLink(siteName);
		}
	}, [siteName]);

	return (
		<>
			<Flex
				bgGradient='linear(to-r, blue.500, blue.200)'
				color='white'
				height='128px'
				zIndex={1200}>
				{/* logo and web name */}
				<Flex maxWidth='1920px' width='100%' marginX='auto' justifyContent='space-between'>
					<Flex>
						<Image
							src='/assets/svg/pcso.svg'
							margin={['', '', '', '', '8px', '10px', '15px']}
							width={['', '', '', '', '70px', '70px', '80px']}
							height={['', '', '', '', '70px', '70px', '80px']}
							justifyContent='center'
							alignSelf='center'
						/>
						<Text
							fontWeight={['', '', '', '', 'bold', '', '', 'black']}
							fontSize={['', '', '', '', '28px', '32px', '35px']}
							justifyContent='center'
							alignSelf='center'
							textAlign='center'
							cursor='default'>
							PANALO 29
						</Text>
					</Flex>
					{/* nav links */}

					<Flex justifyContent='space-evenly' width='50%'>
						{navlink.map((item, index) => (
							<Flex
								key={index}
								justifyContent='space-evenly'
								alignSelf='center'
								marginX={['', '', '', '', '5px', '5px', '15px']}>
								<Text
									onClick={() => {
										handleClick(item);
										setDate(dayjs(getServerTime.data?.currTime).tz('Asia/Kuala_Lumpur').$d);
										if (item.name === 'pcsoResult') {
											setPcsoTab('PCSO Results Today');
											sessionStorage.setItem('swiperIndex', 0);
										}
									}}
									color={storeLink === item.name ? '#FCDF09' : 'white'}
									fontSize={['', '', '', '', '20px', '24px', '24px']}
									fontWeight='600'
									_hover={{
										color: '#FCDF09',
										borderBottom: '1px',
										borderColor: '#FCDF09',
									}}
									cursor={'pointer'}>
									{item.label}
								</Text>
							</Flex>
						))}
					</Flex>

					<Flex
						justifyContent='center'
						alignSelf='center'
						marginX={['', '', '', '', '10px', '12px', '18px']}>
						<Text width={['', '', '', '', '200px', '240px', '260px']} cursor='default' />
					</Flex>
				</Flex>
			</Flex>

			<Box marginX='auto'>
				<Outlet context={[date, setDate, maxDate]}></Outlet>
			</Box>
		</>
	);
};

export default WebNavbar;

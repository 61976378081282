import { Box, Divider, Flex, Text, useMediaQuery } from '@chakra-ui/react';
import { useQuery } from '@tanstack/react-query';
import React, { Fragment } from 'react';
import { useParams } from 'react-router-dom';
import { getStlCityApi, getStlGameTypeApi } from '../../api/stlApi';
import Loading from '../../utils/Loading';
import NumText from '../../utils/NumText';
import Result30Days from '../Result30Days';
import Result30DaysMobile from '../Result30DaysMobile';
import ResultCard from '../ResultCard';

const StlReuseResultMobile = () => {
	const { gameName, locationName } = useParams();
	const getStlCity = useQuery(['getStlCityApi', gameName, locationName], () =>
		getStlCityApi({ gameName: gameName, location: locationName }),
	);
	const getStlGameType = useQuery(['getStlGameTypeApi', gameName, locationName], () =>
		getStlGameTypeApi({ gameName: `${gameName} - ${locationName}` }),
	);

	return (
		<>
			{getStlCity.isLoading ? (
				<Loading />
			) : (
				<Flex alignItems='center' justifyContent='center' flexDirection='column'>
					{getStlCity.data !== undefined ? (
						getStlCity.isSuccess && (
							<Flex display={['flex', 'flex', 'flex', 'flex', 'none']} flexDirection='column'>
								{getStlCity.data?.length !== 0 ? (
									<>
										{getStlCity.data?.map((items, index) => {
											return (
												<Flex flexDirection='column' key={index}>
													<Text fontSize='20px' fontWeight='600' color='blue.500'>
														{items.locationName}
													</Text>
													{items.games.map((item, index) => {
														return (
															<Flex flexDirection='column' key={index}>
																{item.result.map((result) => {
																	return (
																		<ResultCard
																			width={['300px', '330px', '450px', '450px', '835px']}
																			height={['150px', '170px', '200px', '200px', '375px']}
																			imageWidth='50%'
																			title={item.name}
																			numberWidth={['60%', '60%']}
																			backgroundColor={
																				locationName === 'All' || locationName === 'all'
																					? '#ECECEC'
																					: '#EDF3FF'
																			}
																			imageBgColor={
																				locationName === 'All' || locationName === 'all'
																					? '#F5F5F5'
																					: '#FFFFFF'
																			}
																			borderIsBlue={
																				locationName === 'All' || locationName === 'all'
																					? false
																					: true
																			}
																			src={result.image}
																			date={result.date}
																			time={result.time}
																			winner={result.winner}
																			marginBottom={5}
																			hasDate={result.id === 1 ? true : false}
																			key={result.id}>
																			{result.resultNum.map((num, index) => {
																				return (
																					<Flex
																						width={['30px', '30px', '45px', '45px', '88.14px']}
																						height={['30px', '30px', '45px', '45px', '88.14px']}
																						margin='auto'
																						borderRadius={180}
																						alignItems='center'
																						justifyContent='center'
																						backgroundColor='blue.500'
																						key={index}>
																						<NumText
																							fontSize={['16px', '16px', '18px', '18px', '34px']}>
																							{num.number}
																						</NumText>
																					</Flex>
																				);
																			})}
																		</ResultCard>
																	);
																})}
															</Flex>
														);
													})}
												</Flex>
											);
										})}
									</>
								) : (
									<>
										<Text
											justifyContent='center'
											align='center'
											fontSize='16px'
											fontWeight='500'
											color='#000000'>
											No Data
										</Text>
									</>
								)}
							</Flex>
						)
					) : (
						<Text
							mt={'50vh'}
							textAlign='start'
							justifyContent='center'
							align='center'
							fontSize='16px'
							fontWeight='500'
							color='#000000'>
							No Data
						</Text>
					)}

					{locationName !== 'All' && locationName !== 'all' ? (
						<>
							<Divider borderWidth='1px' marginY='5px' width='90%' zIndex={-1} />
							<Flex justifyContent='center' alignItems='center'>
								<Result30DaysMobile
									data={getStlGameType.data?.tableData ?? []}
									hasTime={false}
									gameName={`${locationName}`}
								/>
							</Flex>
						</>
					) : null}
				</Flex>
			)}
		</>
	);
};

const StlReuseResultDesktop = () => {
	const { gameName, locationName } = useParams();
	const getStlCity = useQuery(['getStlCityApi', gameName, locationName], () =>
		getStlCityApi({ gameName: gameName, location: locationName }),
	);

	const getStlGameType = useQuery(['getStlGameTypeApi', gameName, locationName], () =>
		getStlGameTypeApi({ gameName: `${gameName} - ${locationName}` }),
	);

	let stringTitle = gameName;
	stringTitle =
		stringTitle.slice(0, 3).toUpperCase() +
		' ' +
		stringTitle.slice(3, 4).toUpperCase() +
		stringTitle.slice(4);

	return (
		<Flex flexDirection='column'>
			{getStlCity.isLoading || getStlGameType.isLoading ? (
				<Box marginY='120px' justifyContent='center' alignSelf='center'>
					<Loading />
				</Box>
			) : (
				<>
					{locationName === 'All' || locationName === 'all' ? (
						<Text
							fontWeight='600'
							fontSize='32px'
							color='blue.500'
							paddingTop='34px'
							marginX='auto'
							width={['300px', '330px', '450px', '450px', '835px']}
							display={['none', 'none', 'none', 'block']}
							cursor='default'>
							All Location
						</Text>
					) : null}
					{getStlGameType.data !== undefined ? (
						getStlCity.isSuccess &&
						getStlCity.data.map((item, index) => {
							return (
								<Fragment key={index}>
									<Text
										fontWeight='600'
										fontSize='32px'
										color='blue.500'
										paddingTop='34px'
										marginX='auto'
										paddingBottom='52px'
										width={['300px', '330px', '450px', '450px', '835px']}
										display={['none', 'none', 'none', 'block']}
										cursor='default'>
										{stringTitle} - {item.locationName}
									</Text>
									<Flex
										flexDirection='column'
										display={['none', 'none', 'none', 'flex']}
										gap={['50px', '50px', '50px', '50px', '50px', '50px']}>
										{item.games[0]?.result?.map((data, index) => {
											return (
												<ResultCard
													key={index}
													width={['300px', '330px', '450px', '450px', '835px']}
													height={['139px', '139px', '200px', '200px', '375px']}
													imageWidth='50%'
													title={item.games[0]?.fullName}
													numberWidth={['60%', '80%']}
													backgroundColor='#ECECEC'
													imageBgColor='#F5F5F5'
													src={data.image}
													date={data.date}
													time={data.time}
													winner={data.winner}
													marginBottom='52px'
													hasDate={true}>
													{data.resultNum.map((item, index) => {
														return (
															<Flex
																width={['30px', '32px', '45px', '45px', '88px']}
																height={['30px', '32px', '45px', '45px', '88px']}
																margin='auto'
																borderRadius={180}
																alignItems='center'
																justifyContent='center'
																backgroundColor='blue.500'
																key={index}>
																<NumText fontSize={['16px', '16px', '18px', '26px']}>
																	{item.number}
																</NumText>
															</Flex>
														);
													})}
												</ResultCard>
											);
										})}
									</Flex>

									{locationName !== 'All' && locationName !== 'all' ? (
										<Box>
											<Result30Days
												gameName={locationName}
												data={getStlGameType.data.tableData ?? []}
												hasTime={false}
											/>
										</Box>
									) : null}
								</Fragment>
							);
						})
					) : (
						<Text textAlign='center' fontSize='24px' fontWeight='500' width='100%' color='#000000'>
							No Data
						</Text>
					)}
				</>
			)}
		</Flex>
	);
};

const StlReuseResult = () => {
	const [isLargerThan1024] = useMediaQuery('(min-width: 1024px)');

	return <>{isLargerThan1024 ? <StlReuseResultDesktop /> : <StlReuseResultMobile />}</>;
};
export default StlReuseResult;

import { Box, Divider, Flex, Grid, Image, Text, useMediaQuery } from '@chakra-ui/react';
import { useQuery } from '@tanstack/react-query';
import dayjs from 'dayjs';
import React, { useEffect } from 'react';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { useOutletContext } from 'react-router-dom';
import { getServerTimeApi } from '../../api/publicApi';
import { getStlTodayApi } from '../../api/stlApi';
import Loading from '../../utils/Loading';
import NumText from '../../utils/NumText';
import ResultCard from '../ResultCard';

const StlTodayResult = () => {
	const [isLargerThan1024] = useMediaQuery('(min-width: 1024px)');
	const imageWidth = '50%';
	const [date, setDate, maxDate] = useOutletContext();
	const getStlResult = useQuery(['getStlTodayApi', date], () =>
		getStlTodayApi(dayjs(date).format('YYYY-MM-DD')),
	);
	const getServerTime = useQuery(['getServerTimeApi'], getServerTimeApi);
	useEffect(() => {
		if (getServerTime.isSuccess) {
			setDate(dayjs(getServerTime.data?.currTime).tz('Asia/Kuala_Lumpur').$d);
		}
	}, [getServerTime.isSuccess]);

	return (
		<Flex
			flexDirection='column'
			// marginX={5}
			// paddingX='12px'
			maxWidth='1440px'
			// marginX={['auto','','','','','16px','']}
			marginX='auto'
			paddingBottom={20}
			// paddingX={[1, 1, 20, 10, 5, 5, 5]}
			alignItems='center'>
			<Flex marginX={'16px'} flexDirection='column'>
				{getStlResult.isLoading ? (
					<Box minHeight={40}></Box>
				) : (
					<Flex
						flexDirection='row'
						justifyContent='space-between'
						alignItems='center'
						width={['300px', '330px', '450px', '445px', '908px', '100%']}>
						<Text
							fontWeight='600'
							cursor='default'
							fontSize='32px'
							color='blue.500'
							paddingTop='34px'
							paddingBottom='52px'
							// paddingX={[0, 0, 0, 0, 0, 3]}
							display={['none', 'none', 'none', 'none', 'block']}>
							STL Results Today {dayjs(date).format('DD/MM/YYYY')}
						</Text>
						<Box
							paddingBottom={5}
							display={['none', 'none', 'none', 'none', 'block']}
							cursor='pointer'>
							<DatePicker
								zIndex={2000}
								selected={date}
								minDate={dayjs('03/08/2023')}
								maxDate={maxDate}
								onChange={(date) => setDate(date)}
								customInput={
									<Image
										src={
											isLargerThan1024 ? '/assets/svg/calendarWeb.svg' : '/assets/svg/calendar.svg'
										}
										width={isLargerThan1024 ? '52px' : '30px'}
										height={isLargerThan1024 ? '52px' : '30px'}
									/>
								}
							/>
						</Box>
					</Flex>
				)}

				{getStlResult.isLoading ? (
					<Loading />
				) : (
					getStlResult.data?.map((location, index, { length }) => {
						return (
							<Flex key={index} width='100%' flexDirection='column' alignItems='center'>
								<Flex
									width={['300px', '330px', '450px', '450px', '908px', '100%']}
									alignItems='center'
									justifyContent='center'>
									<Text
										fontWeight='600'
										fontSize={['24px', '24px', '24px', '24px', '40px']}
										color='blue.500'
										paddingTop={['24px', '24px', '24px', '24px', '34px']}
										paddingBottom='24px'
										// paddingX={[0, 0, 0, 0, 2]}
										width={['300px', '330px', '450px', '450px', '908px', '100%']}
										cursor='default'>
										{location.locationName}
									</Text>
								</Flex>
								{location.games.map((game, index) => {
									return (
										<Flex
											key={index}
											alignItems='center'
											flexDirection='column'
											paddingBottom='24px'>
											<Text
												fontWeight='500'
												fontSize={['14px', '14px', '14px', '14px', '20px']}
												color='#000000'
												paddingBottom='16px'
												cursor='default'
												// marginTop={4}
												// paddingX={[0, 0, 0, 0, 2]}
												width={['300px', '330px', '450px', '450px', '908px', '100%']}
												// display={['none', 'none', 'none', 'block']}
											>
												{game.name}
											</Text>
											{/* <Text
											width={['300px', '330px', '450px', '445.95px']}
											cursor='default'
											display={['block', 'block', 'block', 'none']}>
											{game.name}
										</Text> */}
											<Grid
												templateColumns={[
													'repeat(1, 1fr)',
													'repeat(1, 1fr)',
													'repeat(1, 1fr)',
													'repeat(1, 1fr)',
													'repeat(2, 1fr)',
													'repeat(3, 1fr)',
													'repeat(3, 1fr)',
												]}
												alignItems='center'
												justifyContent='center'
												gap={['16px', '16px', '16px', '16px', '48px']}>
												{game.result.map((items, index) => {
													return (
														<Flex flexDirection='column' key={index}>
															<ResultCard
																width={['300px', '330px', '450px', '450px', '430px', '100%']}
																height={['139px', '139px', '223px', '223px', '223px']}
																imageWidth={imageWidth}
																numberWidth={['70%', '85%', '70%', '70%', '80%']}
																backgroundColor='#ECECEC'
																imageBgColor='#F5F5F5'
																src={items.image}
																date={items.date}
																time={items.time}
																winner={items.winner}
																displayDate='block'
																title={game.fullName}>
																{items.resultNum.map((item, index) => {
																	return (
																		<Flex
																			key={index}
																			width={['30px', '35px', '45px', '45px', '62px']}
																			height={['30px', '35px', '45px', '45px', '62px']}
																			// margin='auto'
																			borderRadius={180}
																			alignItems='center'
																			justifyContent='center'
																			backgroundColor='blue.500'>
																			<NumText fontSize={['16px', '16px', '18px', '18px', '30px']}>
																				{item.number}
																			</NumText>
																		</Flex>
																	);
																})}
															</ResultCard>
														</Flex>
													);
												})}
											</Grid>
										</Flex>
									);
								})}
								{/* it won't show the divider when it is on the last loop. */}
								{length - 1 !== index && (
									<Divider
										marginY={5}
										borderWidth={1.5}
										display={['none', 'none', 'none', 'none', 'block']}
									/>
								)}
							</Flex>
						);
					})
				)}
			</Flex>
		</Flex>
	);
};

export default StlTodayResult;
